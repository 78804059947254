<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-31 19:17:00
 * @LastEditTime: 2022-09-29 17:39:31
 * @LastEditors: cyy
 * @Description: 推广商品管理
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\promoter\promoteManagement.vue
-->
<template>
  <div id="promoteManagement">
    <div class="member-contain" :class="{ hiddenList }">
      <div class="title">
        当前分组：{{ $route.query.groupName }}（{{ gourpPhotos.length }}）
      </div>
      <div class="list" :class="{ hiddenList }" ref="memberList">
        <span
          class="item flex-center"
          v-for="item in gourpPhotos"
          :key="item.p_user_id"
        >
          <img class="photo" :src="item.uphoto" alt="" />
          <span class="name">{{ item.uname }}</span>
        </span>
      </div>
      <div class="show-more" @click="showMore" v-if="hiddenList">展开全部</div>
    </div>
    <pagination2
      :option="post"
      ref="list"
      url="/Promoter/goodsList"
      class="main"
    >
      <template v-slot:default="{ tableData }">
        <div class="select-item flex-center">
          <el-button type="primary" @click="networkschoolSelectStatus = true">
            添加商品
          </el-button>
          <div>
            <el-select
              v-model="post.goods_type"
              style="width: 120px"
              size="medium"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-input
              size="medium"
              v-model="post.search"
              style="width: 218px"
              class="ml20"
              placeholder="输入名称搜索"
            ></el-input>
          </div>
        </div>
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
          class="table-data"
        >
          <el-table-column label="商品" prop="" min-width="150px">
            <template slot-scope="{ row }">
              <div class="flex-center good-cover">
                <img class="cover" :src="row.cover_img | imageslim" alt="" />
                <div class="info">
                  <div class="name">{{ row.name }}</div>
                  <div class="price">￥{{ row.play_price }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="">
            <template slot-scope="{ row }">
              {{ row.goods_type | courseTypeText }}
            </template>
          </el-table-column>
          <el-table-column label="佣金比例" min-width="150px" align="center">
            <template slot-scope="{ row }">
              <div
                v-if="changeCommissionPercentArray.indexOf(row.p_goods_id) > -1"
              >
                <el-input-number
                  :min="0"
                  :max="100"
                  :precision="2"
                  :step="0.01"
                  :key="row.p_goods_id"
                  size="small"
                  v-model="row.commission_percent"
                ></el-input-number>
                <el-button
                  type="text"
                  class="ml10"
                  @click="saveCommissionPercent(row)"
                >
                  保存
                </el-button>
                <el-button
                  style="color: #999999"
                  type="text"
                  class="ml10"
                  @click="
                    ;(row.commission_percent = row.old_commission_percent),
                      changeCommissionPercent(row.p_goods_id)
                  "
                >
                  取消
                </el-button>
              </div>
              <el-button
                v-else
                type="text"
                @click="
                  ;(row.old_commission_percent = row.commission_percent),
                    changeCommissionPercent(row.p_goods_id)
                "
              >
                {{ row.commission_percent }}%
                <i class="el-icon-edit" style="color#0AA29B;"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="总推广订单" prop="count"></el-table-column>
          <el-table-column
            label="总订单金额"
            prop="goods_order_price"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-popconfirm
                title="是否确定移除该内容？"
                @confirm="removeGood(row)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <!-- 选择商品 -->
    <networkschoolSelect
      @selectChangenetworkschool="closeGoodsConfirm"
      :p_group_id="this.$route.query.group_id"
      @singleselectcontentval="singleselectcontentval"
      title="添加商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="11"
      :id_type="5"
    ></networkschoolSelect>
    <!--设置佣金-->
    <el-dialog
      :visible.sync="setCommission"
      width="900px"
      class="addGoodsConfirm"
      @close="prev"
      :close-on-click-modal="false"
    >
      <div slot="title" class="flex-center">
        <div class="title_font">设置商品分佣比例</div>
        <div class="redcolor tip">推广奖励比例需≤100%</div>
      </div>
      <el-table
        empty-text="暂无数据"
        :data="addGoods"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
          height: '40px',
        }"
        style="width: 100%; height: 550px"
      >
        <el-table-column prop="transcode_type" label="内容" min-width="150">
          <template slot-scope="{ row }">
            <div class="flex-center good-cover">
              <img class="cover" :src="row.cover" alt="" />
              <div class="info">
                <div class="name">{{ row.name }}</div>
                <div class="price">￥{{ row.price }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="video_spec" label="类型">
          <template slot-scope="{ row }">
            {{ row.course_type | courseTypeText }}
          </template>
        </el-table-column>
        <el-table-column prop="video_spec" label="推广奖金比例" min-width="100">
          <template slot-scope="{ row }">
            <div class="flex-center">
              <el-input-number
                :min="0"
                :max="100"
                :precision="2"
                :step="0.01"
                size="small"
                v-model="row.commission_percent"
                style="margin-right: 10px"
              ></el-input-number>
              %
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="video_spec" label="佣金(元)">
          <template slot-scope="{ row }">
            <span style="color: #ff3535">
              {{
                mathEvaluate(`${row.price}*(${row.commission_percent} / 100)`)
                  | floatNumber
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="{ row }">
            <el-popconfirm
              title="是否确定移除该内容？"
              @confirm="removeSelectedGood(row)"
            >
              <el-button slot="reference" type="text">移除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="prev('prev')">上一步</el-button>
        <el-button type="primary" @click="saveGoods">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import { evaluate } from 'mathjs'

export default {
  name: 'promoteManagement',

  components: {
    networkschoolSelect,
  },

  data() {
    return {
      hiddenList: false,
      post: {
        goods_type: 0,
        search: '',
        p_group_id: this.$route.query.group_id,
      },

      gourpPhotos: [],

      // 选择商品弹窗
      networkschoolSelectStatus: false,

      // 修改佣金比例的数组
      changeCommissionPercentArray: [],

      // 等待添加的商品列表
      addGoods: [],

      // 设置佣金弹窗
      setCommission: false,

      options: [
        { value: 0, label: '全部类型' },
        { value: 1, label: '直播课' },
        { value: 2, label: '小班课' },
        { value: 3, label: '录播课' },
        { value: 4, label: '系列课' },
        { value: 5, label: '公开课' },
        { value: 6, label: '图文' },
      ],
    }
  },

  mounted() {
    this.getGourpPhotos()
  },

  methods: {
    mathEvaluate(val) {
      return evaluate(val)
    },

    // 获取分组头像
    async getGourpPhotos() {
      const { data } = await this.$http({
        url: '/Promoter/userList',
        data: {
          p_group_id: this.$route.query.group_id,
        },
      })
      this.gourpPhotos = data
      this.getMemberListHeight()
    },

    // 获取成员列表高度 判断是否大于两行
    getMemberListHeight() {
      this.hiddenList = this.$refs.memberList.offsetHeight > 72
    },

    // 显示全部的成员
    showMore() {
      this.hiddenList = false
    },

    // 修改佣金比例
    changeCommissionPercent(goods_id) {
      let index = this.changeCommissionPercentArray.indexOf(goods_id)
      if (index > -1) {
        this.changeCommissionPercentArray.splice(index, 1)
      } else {
        this.changeCommissionPercentArray.push(goods_id)
      }
    },

    // 保存佣金比例
    async saveCommissionPercent(row) {
      await this.$http({
        url: '/Promoter/savePercent',
        data: {
          p_goods_id: row.p_goods_id,
          commission_percent: row.commission_percent,
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.changeCommissionPercentArray = []
      this.$refs.list.reset()
    },

    // 移除商品
    async removeGood(row) {
      await this.$http({
        url: '/Promoter/delGoods',
        data: {
          p_goods_id: row.p_goods_id,
        },
      })
      this.$root.prompt({
        type: 'success',
        msg: '操作成功',
      })
      this.$refs.list.reset()
    },

    // 关闭商品弹窗
    closeGoodsConfirm() {
      // 如果设置佣金的弹窗显示 就不关闭选择商品的弹窗
      if (!this.setCommission) {
        this.networkschoolSelectStatus = false
      }
    },

    // 添加商品的回调
    singleselectcontentval(val) {
      this.addGoods = val.map(item =>
        Object.assign({}, item, {
          cover: item.photo || item.cover_img,
          price: item.price || item.play_price,
          course_type: item.course_type || (item.open_class_id ? 5 : 6),
          goods_type: item.course_type || (item.open_class_id ? 5 : 6),
          commission_percent: 0,
          goods_id: item.course_id || item.open_class_id,
          id: Math.random(),
        })
      )
      this.setCommission = true
    },

    // 删除选中商品
    removeSelectedGood(item) {
      const index = this.addGoods.indexOf(item.id)
      this.addGoods.splice(index, 1)
    },

    // 上一步
    prev(state) {
      if (this.prevState) {
        this.prevState = null
        return
      }

      this.addGoods = []
      this.setCommission = false

      // 如果用户是点击上一步关闭弹窗 那就设置一个状态阻止第二次弹窗的关闭回调
      if (state === 'prev') {
        this.prevState = true
      } else {
        // 如果用户点击X来关闭弹窗 就直接把选择商品弹窗也关闭掉
        this.closeGoodsConfirm()
      }
    },

    // 保存
    async saveGoods() {
      if (this.addGoods.length > 0) {
        await this.$http({
          url: '/Promoter/addGoods',
          data: {
            p_group_id: this.$route.query.group_id,
            list: this.addGoods,
          },
        })
        this.$root.prompt({
          type: 'success',
          msg: '操作成功',
        })
        this.prev()
        this.closeGoodsConfirm()
        this.$refs.list.reset()
      } else {
        this.$root.prompt('内容不能为空！')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.member-contain {
  padding: 20px 0;
  overflow: hidden;
  box-sizing: border-box;
  background: #ffffff;
  &.hiddenList {
    height: 138px;
  }
  .title {
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    padding: 0 20px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &.hiddenList {
      height: 72px;
    }
    .item {
      margin-top: 14px;
      margin-left: 20px;
      .photo {
        width: 22px;
        height: 22px;
        border-radius: 11px;
      }
      .name {
        font-size: 14px;
        color: #666666;
        line-height: 1.5;
        margin-left: 10px;
      }
    }
  }
  .show-more {
    font-size: 12px;
    color: #0aa29b;
    line-height: 1.5;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
  }
}
.main {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  .select-item {
    margin-bottom: 20px;
    justify-content: space-between;
  }
}
.good-cover {
  .cover {
    width: 80px;
    height: 45px;
    background: #f5f5f5;
    border-radius: 2px;
    object-fit: contain;
  }
  .info {
    margin-left: 10px;
    .name {
      font-size: 14px;
      color: #333333;
    }
    .price {
      margin-top: 12px;
      font-size: 14px;
      color: #ff3535;
    }
  }
}

.addGoodsConfirm {
  .title_font {
    font-size: 14px;
    color: #333333;
  }

  .tip {
    font-size: 13px;
    color: #ff3535;
    line-height: 13px;
    margin-left: 10px;
  }
}
</style>
